import { getInstance } from "./index";
import { default as store } from "@/store";

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    if (!authService.isAuthenticated) {
      // 認証されていない場合は、ログインページにリダイレクト
      authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    }

    // 薬局ロールもしくは管理者ロールのいずれも持たない場合は、Forbiddenページへ。
    if (!authService.hasPharmacyRole && !authService.hasAdminRole) {
      return next("/forbidden");
    }

    // 選択薬局がない場合は、選択ページへ。
    if (!store.getters["selectingPharmacy/id"]) {
      // リダイレクトループしないように、to.pathをチェック
      if (to.path !== "/select-pharmacy") {
        return next("/select-pharmacy");
      }
    }
    return next();
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};
