import "vue-awesome/icons";

import App from "@/App.vue";
import { Auth0Plugin } from "./auth";
import Axios from "./plugins/axios";
import CoreuiVue from "@coreui/vue";
import Icon from "vue-awesome/components/Icon.vue";
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import VueCompositionApi from "@vue/composition-api";
import { iconsSet as icons } from "./assets/icons/icons.js";
import router from "./router";
import store from "./store";

/* CoreUI */
Vue.use(CoreuiVue);

/* Vue Composition API */
Vue.use(VueCompositionApi);

/* Vue Awesome */
Vue.component("v-icon", Icon);

/* Auth0 */
if (!process.env.VUE_APP_AUTH0_TENANT_DOMAIN) {
  throw "Set VUE_APP_AUTH0_TENANT_DOMAIN in environment variables";
}

if (!process.env.VUE_APP_AUTH0_CLIENT_ID) {
  throw "Set VUE_APP_AUTH0_CLIENT_ID in environment variables";
}

const domain = process.env.VUE_APP_AUTH0_TENANT_DOMAIN;
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRedirectCallback: (appState: any) => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  },
});

/* vue-clipboard2 */
VueClipboard.config.autoSetContainer = true; // this line must be wrote before 'Vue.use(VueClipboard)'
Vue.use(VueClipboard);

/* Axios */
Vue.prototype.$axios = Axios;

/* Main */
Vue.config.productionTip = false;
new Vue({
  router,
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  icons,
  store,
  render: (h) => h(App),
}).$mount("#app");
