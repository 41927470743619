<template>
  <div>
    <CCard>
      <CCardHeader>アカウント情報</CCardHeader>
      <CCardBody>
        <CForm>
          <CInput label="名前" :value="user.name" horizontal plaintext disabled />
          <CInput label="ログイン用メールアドレス" :value="user.email" horizontal plaintext disabled />
        </CForm>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardHeader>所属する薬局一覧</CCardHeader>
      <CCardBody>
        <CDataTable hover bordered fixed :items="pharmacies" :fields="pharmacyFields" />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
export default {
  name: "Profile",
  data() {
    return {
      user: this.$auth.user,
      pharmacyFields: [
        { key: "name", label: "薬局名" },
        { key: "micode", label: "医療機関コード" },
        { key: "address", label: "住所" },
        { key: "phone", label: "電話番号" },
        { key: "fax", label: "FAX番号" },
      ],
    };
  },
  computed: {
    pharmacies() {
      return this.$store.state.core.pharmacies.list;
    },
  },
  async created() {
    await this.$store.dispatch("core/pharmacies/fetch");
  },
};
</script>
