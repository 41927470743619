








































































import { getInstance } from "@/auth";
import { ChatMessageClass } from "@/models/lib/vue-advanced-chat/chatMessageClass";
import { ChatRoomClass } from "@/models/lib/vue-advanced-chat/chatRoomClass";
import axios from "@/plugins/axios";
import { selectingPharmacy } from "@/store/selectingPharmacy";
import { doDownload } from "@/utility/windowHelper";
import InquiryMessageModal from "@/views/inquiries/InquiryMessageModal.vue";
import InquiryTitleModal from "@/views/inquiries/InquiryTitleModal.vue";
import NewInquiryModal from "@/views/inquiries/NewInquiryModal.vue";
import DeleteConfirmationModal from "@/views/shared/DeleteConfirmationModal.vue";
import ImageModal from "@/views/shared/ImageModal.vue";
import { computed, defineComponent, ref } from "@vue/composition-api";
import { createConsumer } from "actioncable-jwt";
import ChatWindow from "vue-advanced-chat";
import "vue-advanced-chat/dist/vue-advanced-chat.css";
import {
  ChatFile,
  ChatRoom,
  DeleteMessage,
  EditMessage,
  FetchedMessage,
  MenuAction,
  MenuActionDetail,
  MessageActionHandler,
  OpenFile,
  SendMessage,
  TextareaActionHandler,
} from "vue/types/vue-advanced-chat";

export default defineComponent({
  name: "Inquiries",
  components: { ChatWindow, NewInquiryModal, DeleteConfirmationModal, InquiryMessageModal, InquiryTitleModal, ImageModal },
  setup() {
    let roomCable: ActionCableJwt.Cable | undefined = undefined;
    let messageCable: ActionCableJwt.Cable | undefined = undefined;
    let roomChannel: ActionCableJwt.Channel | undefined = undefined;
    let messageChannel: ActionCableJwt.Channel | undefined = undefined;
    let creatingMessageChannel = false;

    const isOpenNewInquiryModal = ref<boolean>(false);
    const isOpenDeleteModal = ref<boolean>(false);
    const isOpenImageModal = ref<boolean>(false);
    const isOpenInquiryMessageModal = ref<boolean>(false);
    const isOpenInquiryTitleModal = ref<boolean>(false);
    const messageModalMode = ref<string>("send"); // possibly ["send", "edit"]
    const inquiryTitle = ref<string>("");
    const imageFile = ref<ChatFile>(null);
    const loadingRooms = ref<boolean>(true);
    const roomsLoaded = ref<boolean>(false);
    const messagesLoaded = ref<boolean>(true);
    const loadFirstRoom = ref<boolean>(false);
    const roomId = ref<number | undefined | null>(null);
    const messages = ref<ChatMessageClass[]>([]);
    const submittingMessage = ref<SendMessage | EditMessage>(null);
    const deletingMessage = ref<DeleteMessage>(null);
    const rooms = ref<ChatRoomClass[]>([]);
    const showFooter = computed(() => {
      const selectingRoom = rooms.value.find((r: ChatRoom) => r.id === roomId.value);
      return !(selectingRoom?.completed || selectingRoom?.closed || false);
    });

    const currentUserId = ref<number | undefined>(undefined);

    const pharmacyId = typeof selectingPharmacy.state === "object" ? selectingPharmacy.state?.id : 0;

    async function subscribeRooms() {
      const jwtToken = await getInstance().getJwtToken();
      roomChannel?.unsubscribe();
      roomCable?.disconnect();

      roomCable = createConsumer(`${process.env.VUE_APP_V2_API_CORE_HOST_WS}/cable`, jwtToken);
      roomChannel = roomCable?.subscriptions.create(
        { channel: "PharmacyInquiryRoomChannel", pharmacy_id: pharmacyId },
        {
          // connected: () => console.log("connected rooms"),
          // disconnected: () => console.log("disconnected rooms"),
          received: (newRoom: ChatRoomClass) => {
            const oldIndex = rooms.value.findIndex((r: ChatRoomClass) => r.id === newRoom.id);
            if (oldIndex >= 0) {
              rooms.value[oldIndex] = ChatRoomClass.create(newRoom);
              rooms.value = [...rooms.value]; // 画面の再描画のため
            } else {
              const newRoomClass = ChatRoomClass.create(newRoom);
              rooms.value = [newRoomClass, ...rooms.value];
            }
          },
        }
      );
    }

    async function subscribeMessages(roomId: number | undefined) {
      if (!roomId) return;

      try {
        if (creatingMessageChannel) return;
        creatingMessageChannel = true;

        // 接続済みチャネルがあり、roomId に変更がなければ、処理を抜ける
        if (messageChannel) {
          const old_room_id = JSON.parse(messageChannel.identifier).room_id;
          if (old_room_id === roomId) return;
        }

        const jwtToken = await getInstance().getJwtToken();
        await messageChannel?.unsubscribe();
        await messageCable?.disconnect();

        messageCable = await createConsumer(`${process.env.VUE_APP_V2_API_CORE_HOST_WS}/cable`, jwtToken);
        messageChannel = messageCable?.subscriptions.create(
          { channel: "PharmacyInquiryMessageChannel", room_id: roomId },
          {
            connected: () => (creatingMessageChannel = false),
            disconnected: () => (creatingMessageChannel = false),
            received: (newMsg: ChatMessageClass) => {
              const oldMsgIndex = messages.value.findIndex((m: ChatMessageClass) => m.id === newMsg.id);
              if (oldMsgIndex >= 0) {
                messages.value[oldMsgIndex] = ChatMessageClass.create(newMsg);
                messages.value = [...messages.value]; // 画面の再描画のため
              } else {
                const newMessage = ChatMessageClass.create(newMsg);
                newMessage.seen = newMessage.sender_id === currentUserId.value;
                messages.value = [...messages.value, newMessage];
              }
            },
          }
        );
      } finally {
        creatingMessageChannel = false;
      }
    }

    async function sendMessage(m: SendMessage) {
      submittingMessage.value = m;
      messageModalMode.value = "send";
      isOpenInquiryMessageModal.value = true;
    }

    async function editMessage(m: EditMessage) {
      submittingMessage.value = m;
      messageModalMode.value = "edit";
      isOpenInquiryMessageModal.value = true;
    }

    async function deleteMessage(m: DeleteMessage) {
      deletingMessage.value = m;
      isOpenDeleteModal.value = true;
    }

    async function doDeleteMessage() {
      try {
        const url = `/api/v2/core/pharmacy/pharmacies/${pharmacyId}/inquiry_messages/${deletingMessage.value?.messageId}`;
        await axios.delete(url);
      } catch (e) {
        console.error(e);
      }
    }

    async function loadRooms() {
      try {
        const url = `/api/v2/core/pharmacy/pharmacies/${pharmacyId}/inquiry_rooms`;
        const response = await axios.get<Array<ChatRoomClass>>(url);
        rooms.value = response.data.map((room: ChatRoomClass) => ChatRoomClass.create(room));
      } catch (e) {
        console.error(e);
      } finally {
        loadingRooms.value = false;
        roomsLoaded.value = true;
      }
    }

    async function loadMessages(room_id: number | undefined | null) {
      if (!room_id) return;
      try {
        const url = `/api/v2/core/pharmacy/pharmacies/${pharmacyId}/inquiry_rooms/${room_id}/messages`;
        const response = await axios.get<Array<ChatMessageClass>>(url);
        const list = response.data
          .map((message: ChatMessageClass) => ChatMessageClass.create(message))
          .sort((a, b) => (a.createdAt?.toMillis() || 0) - (b.createdAt?.toMillis() || 0));
        messages.value = [...list];
      } catch (e) {
        console.error(e);
      } finally {
        messagesLoaded.value = true;
      }
    }

    async function fetchMessages(message: FetchedMessage) {
      roomId.value = message.room.id;
      await subscribeMessages(message.room.id);
      await loadMessages(message.room.id);
    }

    function openFile({ message, action }: OpenFile) {
      switch (action) {
        case "preview":
          if (message.file) {
            imageFile.value = message.file;
            isOpenImageModal.value = true;
          }
          break;
        case "download":
          if (!message.file?.url) break;
          doDownload(window, message.file?.url, `${message.file.name}${message.file.type}`);
          break;
      }
    }

    function addRoom() {
      isOpenNewInquiryModal.value = true;
    }

    function messageActionHandler({ action }: MessageActionHandler) {
      console.debug("messageActionHandler", action);
    }

    function textareaActionHandler({ message }: TextareaActionHandler) {
      console.debug("textareaActionHandler", message);
    }

    function roomInfo(room: ChatRoom) {
      console.debug("roomInfo", room);
    }

    const menuActions: MenuActionDetail[] = [{ name: "editInquiryTitle", title: "問合せの件名を変更する" }];

    async function menuActionHandler({ roomId, action }: MenuAction) {
      try {
        switch (action.name) {
          case "editInquiryTitle":
            inquiryTitle.value = rooms.value.find((r: ChatRoom) => r.id === roomId)?.name || "";
            isOpenInquiryTitleModal.value = true;
            break;
        }
      } catch (e) {
        console.error(e);
      }
    }

    async function loadCurrentUserIdAndSetup() {
      const response = await axios.get("/api/v2/core/common/users/me");
      currentUserId.value = response.data["user"]["id"];

      if (currentUserId) {
        loadRooms();
        loadMessages(roomId.value);
        subscribeRooms();
      }
    }
    loadCurrentUserIdAndSetup();

    return {
      isOpenNewInquiryModal,
      isOpenDeleteModal,
      isOpenImageModal,
      isOpenInquiryMessageModal,
      isOpenInquiryTitleModal,
      messageModalMode,
      inquiryTitle,
      imageFile,
      loadingRooms,
      roomsLoaded,
      messagesLoaded,
      currentUserId,
      showFooter,
      rooms,
      roomId,
      loadFirstRoom,
      messages,
      submittingMessage,
      menuActions,
      openFile,
      addRoom,
      sendMessage,
      editMessage,
      deleteMessage,
      doDeleteMessage,
      fetchMessages,
      menuActionHandler,
      messageActionHandler,
      textareaActionHandler,
      roomInfo,
    };
  },
});
