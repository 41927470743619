<template>
  <div>
    <CDataTable
      hover
      bordered
      fixed
      responsive
      :items="pharmacies"
      :loading="isLoading"
      :fields="fields"
      :table-filter="{ placeholder: '薬局を検索する', label: '検索', lazy: true }"
      :pagination="true"
      :items-per-page="5"
    >
      <template #actions="{item : pharmacy}">
        <td>
          <CButton color="primary" size="sm" @click="selectPharmacy(pharmacy)">{{ buttonName }}</CButton>
        </td>
      </template>
    </CDataTable>
  </div>
</template>

<script>
import { getInstance } from "src/auth";

export default {
  name: "SelectPharmacyTable",
  props: {
    buttonName: {
      type: String,
      default: "選択",
      // ボタン名を「切り替え」などに変えられるようにpropsとする
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.core.pharmacies.isFetching;
    },
    pharmacies() {
      return this.$store.state.core.pharmacies.list;
    },
    pharmacyIds() {
      return this.pharmacies.map((pharmacy) => pharmacy.id);
    },
    fields() {
      const baseFields = [
        { key: "id", label: "ID" },
        { key: "name", label: "薬局名" },
        { key: "micode", label: "医療機関コード" },
        { key: "actions", label: this.buttonName },
      ];

      if (getInstance().hasAdminRole) {
        baseFields.splice(3, 0, { key: "address1", label: "都道府県" });
      }
      return baseFields;
    },
  },
  async created() {
    await this.$store.dispatch("core/pharmacies/fetch");
  },
  methods: {
    async selectPharmacy(pharmacy) {
      this.$emit("selectPharmacy", pharmacy);
    },
  },
};
</script>
