<template>
  <CDropdown in-nav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img :src="$auth.user.picture" class="c-avatar-img" />
        </div>
        <span class="ml-1">{{ $auth.user.name }}</span>
      </CHeaderNavLink>
    </template>

    <CDropdownHeader class="text-center" color="light">
      <strong>バージョン: {{ require("../../package.json").version }}</strong>
    </CDropdownHeader>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>設定</strong>
    </CDropdownHeader>
    <CDropdownItem :to="{ name: 'ユーザ情報' }">ユーザ情報</CDropdownItem>
    <CDropdownItem v-show="isDevelop" :to="{ name: 'ユーザ復元' }">ユーザ復元(localのみ表示)</CDropdownItem>

    <CDropdownDivider />

    <CDropdownItem @click="logout">ログアウト</CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: "TheHeaderDropdownAccount",
  computed: {
    isDevelop() {
      return process.env.VUE_APP_ENV === "develop";
    },
  },
  methods: {
    logout() {
      this.$auth.logout({
        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
        returnTo: window.location.origin,
      });
    },
  },
};
</script>
